import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning, faDoorOpen, faTemperatureHigh, faTint, faWalking, faWater } from '@fortawesome/free-solid-svg-icons';
import { SensorForDeviceType } from '../../api/util/apiTypes';


const SensorCard: React.FC<SensorForDeviceType> = ({ name, type, value, unit, timestamp }) => {

  let readableDate = '';
  if (timestamp) {
    const date = new Date(timestamp);
    readableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  }

  const getSensorIcon = (type: string) => {
    switch (type) {
    case 'TEMPERATURE':
      return faTemperatureHigh;
    case 'HUMIDITY':
      return faTint;
    case 'MOISTURE':
      return faWater;
    case 'LIGHT':
      return faBoltLightning;
    case 'DOOR':
      return faDoorOpen;
    case 'MOTION':
      return faWalking;
    default:
      return faTemperatureHigh;
    }
  };

  const getSensorUnit = (unit: string) => {
    switch (unit) {
    case 'celsius':
      return '°C';
    case 'fahrenheit':
      return '°F';
    case 'percentage':
      return '%';
    case 'lumens':
      return 'lm';
    default:
      return unit;
    }
  };

  return (
    <div className="max-w-sm border rounded-md overflow-hidden shadow-sm hover:shadow-lg p-4">
      <div className="flex items-center">

        {/* <{getSensorIcon(type)} /> */}
        <FontAwesomeIcon icon={getSensorIcon(type)} className="text-2xl mr-2 text-gray-700" />
        <h2 className="text-xl font-bold text-gray-700">{name}</h2>

        {/* Uncomment and add logic for isOnline to show online status for sensor */}
        {/* <span className={`ml-auto rounded-full h-3 w-3 ${isOnline ? 'bg-green-500' : 'bg-red-500'}`}></span> */}
      </div>
      
      <div className="text-5xl font-bold my-4 text-orange-400 flex">{typeof value === 'number' ?value.toFixed(1): value}
        <h1 className='text-2xl text-gray-700'>{unit ? getSensorUnit(unit): ''}</h1>
      </div>

      {readableDate && (
        <div className="text-sm text-gray-500">
          <span>{readableDate}</span>
        </div>
      )}

      {/* TODO: this will take user to detail page of sensor */}
      {/* <Link to={`/sensor/${id}`} className="text-sky-500 hover:text-sky-800 text-sm">
        DETAILS
      </Link> */}

    </div>
  );
};

export default SensorCard;
