import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserType } from '../../api/util/apiTypes';

// Define the initial state using the UserState type
const initialState: UserType = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  mobile: null,
  business: {
    id: '',
    business_name: '',
  },
  email_verified: false,
  mobile_verified: false,
  is_active: false,
  role: '',
};

// Create the slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Reducer for updating the entire user state
    setUser: (state, action: PayloadAction<UserType>) => {
      return action.payload;
    },
    // Reducer for updating specific fields
    updateUser: (state, action: PayloadAction<Partial<UserType>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    // Reducer to reset user state to initial
    resetUser: () => initialState,
  },
});

// Export the actions
export const { setUser, updateUser, resetUser } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;
