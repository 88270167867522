import React, { useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';

import GroupDeleteModal from './GroupDeleteModal';

interface Group {
  id: string;
  name: string;
  description: string;
  link?: string;
}

interface GroupTableProps {
  groups: Group[];
  onEdit: (data: { id: string; name: string; description: string }) => void;
}

const GroupTable: React.FC<GroupTableProps> = ({ groups, onEdit }) => {   


  // Modal state management
  const [isModalOpen, setIsModalOpen] = useState(false);
  //   const groupId = 'some-group-id'; // Replace with the actual group ID

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className=''>
            <th className="py-2 px-4 bg-gray-200 border-b border-gray-200 text-gray-700 text-left">Group Name</th>
            <th className="py-2 px-4 bg-gray-200 border-b border-gray-200 text-gray-700 text-left">Description</th>
          </tr>
        </thead>
        <tbody>
          {groups.map((group, index) => (
            <tr key={index} className="border-b">
                
              <td className="py-2 px-4 flex items-center space-x-3">
                {/* Delete Button */}
                <MdDelete
                  className='text-red-300 text-md cursor-pointer' 
                  onClick={openModal} 
                />
                <GroupDeleteModal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  groupId={group.id}
                  title="Delete Group"
                />
                {/* Edit button */}
                <FaPen 
                  className='text-gray-400 text-sm cursor-pointer' 
                  onClick={() => onEdit({ id: group.id, name: group.name, description: group.description })} 
                />
                {group.link ? (
                  <Link to={group.link} className="text-sky-500 hover:underline">{group.name} </Link>
                ) : (
                  group.name
                )}

              </td>
              <td className="py-2 px-4">{group.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default GroupTable;
