import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { deleteRule, listRule } from '../../api/rule/ruleApi';
import Spinner from '../Spinner';
import { listSensor } from '../../api/sensor/sensorApi';
import GenericTable from '../generic/GenericTable';

const config = {
  addButtonTitle: 'Add Rule',
  // baseRoute: '/rule'
  deleteApi: deleteRule,
};


const Rule: React.FC = () => {

  const { data: sensors, isLoading: isLoadingSensors, isError: isErrorSensors } = useQuery({
    queryFn: listSensor,
    queryKey: ['listSensors'],
  });
    
  const { data: rules, isLoading, isError } = useQuery({
    queryFn: listRule,
    queryKey: ['tableData'],
  });
    
  const getSensorName = (sensorId: string | undefined) => {
    const sensor = sensors?.find(sensor => sensor.id === sensorId);
    return sensor ? sensor.name : 'Unknown Sensor';
  };
    
  const getCondition = (condition: string | undefined) => {
    if (condition === 'gt') return 'Greater than';
    else if (condition === 'lt') return 'Less than';
    else return '';
  };

  const ruleColumns = [
    {header: 'Name', key: 'name',link: true, linkPath: '/details' },
    {header: 'Sensor', key: 'sensor' },
    {header: 'Condition', key: 'condition' },
    {header: 'Threshold Value', key: 'threshold_value' },
    {header: 'Triggers', key: 'trigger_count' },
  ];

  const tableData = rules
    ?.filter(rule => rule !== null && rule !== undefined)
    .map(rule => ({
      ...rule,
      condition: getCondition(rule.condition),
      sensor: getSensorName(rule.sensor),
      switch: rule.switch ?? '',
    }));

  if (isLoading || isLoadingSensors) return <Spinner />;
  if (isError || isErrorSensors) return <h2 className='flex justify-center text-red-600'>API Error, Try again.</h2>;
    
  return (
    <div>
      {tableData? <GenericTable data={tableData} columns={ruleColumns} config={config}/> : <h2>No Rules to display</h2>}
    </div>
  );
};

export default Rule;
