import { FaBars } from 'react-icons/fa';
import { useAppDispatch } from '../store/hooks';
import { toggleSidebar } from '../features/sidebar/sidebarSlice';
import Logo from './Logo/Logo';


const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="bg-white shadow p-4 flex items-center">
      <button onClick={()=> dispatch(toggleSidebar())} className="text-4xl text-gray-800 p-4 md:hidden">
        <FaBars />
      </button>
      <div className="flex-grow flex justify-center md:justify-start">
        <Logo />
      </div>
      {/* Search is not implemented */}
      {/* <input type="search" placeholder="Search" className="input input-bordered input-primary flex-grow pl-3 ml-5 bg-gray-100 rounded-md" /> */}
    </div>
  );
  
};

export default Header;
