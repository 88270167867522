interface Endpoint {
    path: string;
    requiresAuth: boolean;
  }

interface EndpointGroup {
    [key: string]: Endpoint;
}
  
interface Endpoints {
    users: EndpointGroup;
    devices: EndpointGroup;
    groups: EndpointGroup
    sensors: EndpointGroup;
    switches: EndpointGroup;
    rules: EndpointGroup;
    triggers: EndpointGroup;
}
  
const endpoints: Endpoints = {
  users: {
    getUser: { path: '/users', requiresAuth: true },
    signup: { path: '/signup/business', requiresAuth: false },
    login: { path: '/auth/signin', requiresAuth: false },
    verifyEmail: {path: '/verify-email', requiresAuth: false}
  },
  devices: {
    device: {path: '/device', requiresAuth: true},
    getDevice: { path: '/device/:id', requiresAuth: true },
    uploadDeviceData: { path: '/device/upload/:id/', requiresAuth: true },
    getDevicesStatus: {path: '/devices/status', requiresAuth: true},
    addDevice: {path:'/device/add', requiresAuth: true},
    deviceType: {path:'/device/type', requiresAuth: false}
  },
  groups: {
    getChildren: {path: '/group/:id/children', requiresAuth: true},
    getRoot: {path: '/group/root', requiresAuth: true},
    add: {path: '/group', requiresAuth: true},
    getUpdate: {path: '/group/:id', requiresAuth: true}
  },
  sensors: {
    sensor: {path:'/sensor', requiresAuth: true},
    getSensor: { path: '/sensors/:id', requiresAuth: true },
    getSensorForDevice: {path: '/sensor/device/:device_id', requiresAuth: false},
    sensorType :{path: '/sensor/type', requiresAuth: false}
  },
  switches: {
    switch: {path:'/switch', requiresAuth: true},
    getSwitchForDevice: {path:'/switch/device/:device_id', requiresAuth: true},
    updateSwitchState: {path:'/switch/:switch_id/state/:state', requiresAuth: true}
  },
  rules: {
    rule: {path: '/rule', requiresAuth: true},
  },
  triggers: {
    trigger: {path: '/trigger', requiresAuth: true},
    triggerRule: {path: '/trigger/rule', requiresAuth: true}
  }
};
  
export default endpoints;
  