import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { updateSwitchState } from '../../api/switch/swicthApi';
import { useAppDispatch } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';

interface SwitchCardProps {
    id: string;
  name: string;
  initialState: boolean;
}

const SwitchCard: React.FC<SwitchCardProps> = ({ id, name, initialState }) => {
  const dispatch = useAppDispatch();
  const handleShowError = (error: string) => {
    dispatch(showNotification({ type: 'error', message: error, duration: 3000 }));
  };
    
  const handleShowSuccess = (success: string) => {
    dispatch(showNotification({ type: 'success', message: success, duration: 3000 }));
  };

  const [state, setState] = useState(initialState);
  const {mutate, isPending} = useMutation({
    mutationFn: updateSwitchState,
    onSuccess:()=>{
      handleShowSuccess('Switch state updated successfully.');
    },
    onError: (error)=>{
      handleShowError(error.message);
      setState(!state);
    }
  });

  const toggleSwitch = () => {
    setState(prevState => {
      const newState = !prevState;
      // Add functionality to handle the switch state change
      mutate({
        switchId: id,
        state: newState ? 'true' : 'false'
      });
      return newState;
    });
  };
  return (

    <div className="max-w-sm border rounded-md overflow-hidden shadow-sm p-4 hover:shadow-lg">
      <div className="flex items-center justify-between">
        {/* <FontAwesomeIcon icon={faToggleOn} className="text-2xl mr-2 text-gray-700" /> */}
        <h2 className="text-xl font-bold text-gray-700">{name}</h2>
        {isPending &&<div className="w-4 h-4 border-2 border-blue-500 border-dotted rounded-full animate-spin"></div>}
      </div>
      <div className='flex items-center justify-between'>
        <div className={`text-3xl font-bold my-4 ${state?'text-orange-400': 'text-gray-500'} `}>{state ? 'On' : 'Off'}</div>
        <div className="relative inline-block w-25 h-9 mr-4 align-middle select-none transition duration-200 ease-in">
          <input
            type="checkbox"
            name="toggle"
            id={`toggle-${id}`}
            className="toggle-checkbox absolute block w-9 h-9 rounded-full bg-white border-4 appearance-none cursor-pointer"
            checked={state}
            onChange={toggleSwitch}
            style={{
              left: state ? '2rem' : '0rem',
            }}
          />
          <label
            htmlFor={`toggle-${id}`}
            className={`toggle-label block overflow-hidden h-9 rounded-full cursor-pointer ${
              state ?'bg-orange-400': 'bg-gray-500' 
            }`}
          />
        </div>
      </div>

    </div>
  );
};

export default SwitchCard;

