import React, { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';
import { CreateSwitchType } from '../../api/util/apiTypes';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { createSwitch } from '../../api/switch/swicthApi';

const CreateSwitchForm: React.FC = () => {

  const {deviceId} = useParams();

  // Notification related setup
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleShowError = (error: string) => {
    dispatch(showNotification({ type: 'error', message: error, duration: 3000 }));
  };

  const handleShowSuccess = () => {
    dispatch(showNotification({ type: 'success', message: 'New device created successfully!', duration: 3000 }));
  };

  // Create device setup

  const {mutate, isPending} = useMutation({
    mutationFn: createSwitch,
    onSuccess:(data)=>{
      handleShowSuccess();
      navigate(`/device/${data.device}`);
    },
    onError:(error)=>{
      handleShowError(error.message);
    }
  });
  const [switchState, setSwitch] = useState<CreateSwitchType>({
    name: '',
    description: '',
    device: deviceId?deviceId:''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSwitch({ ...switchState, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    mutate(switchState);
  };

  if (isPending) return <Spinner />;

  return (

    <form className="max-w-md mx-auto p-4 bg-white rounded shadow-md" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={switchState.name}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>

      {/* DEVICE */}
      <div className="mb-4">
        <label htmlFor="device" className="block text-gray-700">Device Id</label>
        <input
          type="text"
          id="device"
          name="device"
          disabled={!!deviceId}
          value={switchState.device}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="description" className="block text-gray-700">Description</label>
        <textarea
          id="description"
          name="description"
          value={switchState.description}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
        >
          Submit
        </button>
      </div>
    </form>

  );
};

export default CreateSwitchForm;
