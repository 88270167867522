import {
  FaTachometerAlt,
  FaTablet,
  FaLayerGroup,
  FaUserTie,
  FaNetworkWired,
} from 'react-icons/fa';
import SidebarItem from '../sidebar/SidebarItem';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { UserType } from '../../api/util/apiTypes';
import { MdLogout } from 'react-icons/md';

const Sidebar: React.FC = () => {
  const user: UserType = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const logoutUser = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="bg-white min-h-screen w-64 shadow-lg">
      <Link to="/">
        <div className="p-5 text-md font-semibold text-gray-700">
          {user.business.business_name}
        </div>
      </Link>
      <div className="pl-4">
        <Link to="/">
          <SidebarItem icon={FaTachometerAlt} label="Dashboard" isActive={isActive('/')} />
        </Link>
        <Link to="/group">
          <SidebarItem icon={FaLayerGroup} label="Group" isActive={isActive('/group')} />
        </Link>
        <Link to="/device">
          <SidebarItem icon={FaTablet} label="Device" isActive={isActive('/device')} />
        </Link>
        <Link to="/rule">
          <SidebarItem icon={FaNetworkWired} label="Rule" isActive={isActive('/rule')} />
        </Link>
        {/* <SidebarItem icon={FaThermometerHalf} label="Sensor" isActive={isActive('/sensor')} />
        <SidebarItem icon={FaChartLine} label="Graph" isActive={isActive('/graph')} /> */}
        {user.role === 'tech_support' && (
          <Link to="/tech-support">
            <SidebarItem icon={FaUserTie} label="Tech Support" isActive={isActive('/tech-support')} />
          </Link>
        )}
      </div>
      <div className="mt-10 p-5 text-md font-semibold text-gray-700">
        OTHERS
      </div>
      <div className="pl-4">
        {/* <SidebarItem icon={FaTools} label="Settings" isActive={isActive('/settings')} />
        <SidebarItem icon={FaQuestionCircle} label="Help" isActive={isActive('/help')} /> */}
        <SidebarItem icon={MdLogout} label="Logout" onClick={logoutUser} isActive={false} />
      </div>
    </div>
  );
};

export default Sidebar;

