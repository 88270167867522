import { useQuery } from '@tanstack/react-query';
import { getDeviceList } from '../../api/device/deviceApi';
import DeviceListCard from './DeviceListCard';
import Spinner from '../Spinner';

const DeviceList: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['deviceList'],
    queryFn: getDeviceList
  });

  if (isLoading) return <Spinner />;
  if (error instanceof Error) return <p className="text-center text-red-500">Error: {error.message}</p>;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {data?.map((device) => (
        <DeviceListCard key={device.id} device={device} />
      ))}
    </div>
  );
};

export default DeviceList;
