interface DetailConfig {
title?: string;
description?: string;

}

interface GenericDetailProps {

    data: Array<{ [key: string]: string | number }>;
    config: DetailConfig;
}

const cssClass = {
  title: 'text-lg font-medium leading-6 text-gray-900',
  value: 'mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0'
};

const GenericDetail:React.FC<GenericDetailProps> = ({data, config})=>{

  const {title, description} = config;

  return (
    <div className='max-w-5xl p-3'>
      <div className="px-4 sm:px-0">
        <h3 className="text-2xl font-semibold leading-7 text-gray-900">{title? title: '-- Title --'}</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{description}</p>
      </div>
      <div className="mt-4 border-t border-gray-300">
        <dl className="divide-y divide-gray-100">
          {data.map((item)=>(
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={item.key}>
              <dt className={cssClass.title}>{item.key}</dt>
              <dd className={cssClass.value}>{item.value}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default GenericDetail;