import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addDevice } from '../../api/device/deviceApi'; // Adjust the import to your actual API file
import { AxiosError } from 'axios';
import { DeviceCredentialsType } from '../../api/util/apiTypes';


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  group: string;
}

const AddDeviceModal: React.FC<ModalProps> = ({ isOpen, onClose, group }) => {
  const [serialNumber, setSerialNumber] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const queryClient = useQueryClient();



  const mutation = useMutation({
    mutationFn: (deviceData: DeviceCredentialsType) =>
      addDevice(deviceData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['childrenAncestors', group] });
      onClose();
      // navigate(`/group/${parent}`, { replace: true });
    },
    onError: (error: AxiosError) => {
      setErrorMessage(error.message);
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate({ serial_number: serialNumber, secret_code: secretCode, group: group });
  };

  if (!isOpen) return null;


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Add Device</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            &times;
          </button>
        </div>
        {mutation.isPending && <p className="text-green-500 mb-4">Adding new device, please wait!</p>}
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="serial_number" className="block text-sm font-medium text-gray-700">
              Serial Number
            </label>
            <input
              type="text"
              id="serial_number"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="secret_code" className="block text-sm font-medium text-gray-700">
              Secret Code
            </label>
            <input
              type="text"
              id="secret_code"
              value={secretCode}
              onChange={(e) => setSecretCode(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-sky-500 text-white rounded-md hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              disabled={mutation.isPending}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDeviceModal;
