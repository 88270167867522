import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import Font Awesome eye icons for show/hide password
import Logo from '../components/Logo/Logo';
import { SignupResponse } from '../api/util/apiTypes';
import { signupApi } from '../api/user/userApi';

export const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);

  const navigate = useNavigate();

  const { mutate, isPending, error } = useMutation<
    SignupResponse,
    AxiosError,
    { email: string; password: string; business_name: string }
  >({
    mutationFn: signupApi,
    onSuccess: () => {
      setSignupSuccess(true);
    },
    onError: (error: AxiosError) => {
      console.error('Signup failed:', error.message);
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    mutate({ email, password, business_name: businessName });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {signupSuccess ? (
        <div className="p-8 bg-white shadow-md rounded-lg text-center">
          <h2 className="text-lg font-medium mb-4">Signup successful!</h2>
          <p className="mb-4">Please verify your email by clicking on the link sent to your email address.</p>
          <button
            onClick={() => navigate('/login')}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            Go to Login
          </button>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="p-8 bg-white shadow-md rounded-lg"
        >
          <div className="flex justify-center mb-6">
            <Logo />
          </div>
          <div className="mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6 relative">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your password
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-md leading-5"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="businessName"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Business name
            </label>
            <input
              type="text"
              id="businessName"
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>
          <button
            type="submit"
            disabled={isPending}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            {isPending ? 'Signing up...' : 'Sign Up'}
          </button>
          {error && <p className="mt-2 text-sm text-red-600">{error.message}</p>}
          <div className="mt-4 text-sm font-medium">
            Already have an account?{' '}
            <Link to="/login" className="text-blue-700 hover:underline">
              Log in
            </Link>
          </div>
        </form>
      )}
    </div>
  );
};

export default Signup;
