import endpoints from '../endpoints';
import api from '../http';
import { CreateRuleType, RuleType } from '../util/apiTypes';


export const createRule = async (ruleData: CreateRuleType): Promise<RuleType>=>{
  const url = endpoints.rules.rule.path;
  const { data } = await api.post<{ data: RuleType }>(url, ruleData);
  return data.data;
};

export const listRule = async (): Promise<RuleType[]>=>{
  const url = endpoints.rules.rule.path;
  const { data } = await api.get<{ data: RuleType[] }>(url);
  return data.data;
};

export const deleteRule = async (rule_id: string) =>{
  let url = endpoints.rules.rule.path;
  url = rule_id? `${url}/${rule_id}`: url;
  const { data } = await api.delete(url);
  return data.data; 
};

export const getRule = async (rule_id: string): Promise<RuleType> =>{
  let url = endpoints.rules.rule.path;
  url = rule_id? `${url}/${rule_id}`: url;
  const { data } = await api.get(url);
  return data.data; 
};

export const updateRule = async (ruleData: CreateRuleType, ruleId: string): Promise<RuleType>=>{
  let url = endpoints.rules.rule.path;
  url = ruleId? `${url}/${ruleId}`: url;
  const { data } = await api.put<{ data: RuleType }>(url, ruleData);
  return data.data;
};