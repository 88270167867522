import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { CreateRuleType, UserType } from '../../api/util/apiTypes';
import { listSensor } from '../../api/sensor/sensorApi';
import { createRule, getRule, updateRule } from '../../api/rule/ruleApi';
import { RootState } from '../../store/store';

const CreateRuleForm: React.FC = () => {

  const { ruleId } = useParams<{ ruleId: string }>(); 
  const [edit, setEdit] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user: UserType = useAppSelector((state: RootState) => state.user);

  const handleShowError = (error: string) => {
    dispatch(showNotification({ type: 'error', message: error, duration: 3000 }));
  };

  const handleShowSuccess = () => {
    dispatch(showNotification({ type: 'success', message: edit?'Rule updated successfully!':'New rule created successfully!', duration: 3000 }));
  };
 
  const { data: ruleData, isLoading: isLoadingRule, isError: isErrorRule } = useQuery({
    queryFn: ()=>getRule(ruleId?ruleId:''),
    queryKey: ['Rule', ruleId],
    enabled: !!ruleId,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (variables: { rule: CreateRuleType; ruleId?: string }) => {
      if (edit && variables.ruleId) {
        return updateRule(variables.rule, variables.ruleId);
      } else {
        return createRule(variables.rule);
      }
    },
    onSuccess: () => {
      handleShowSuccess();
      navigate('/rule');
    },
    onError: (error) => {
      handleShowError(error.message);
    }
  });

  const [rule, setRule] = useState<CreateRuleType>({
    account: '',
    name:'',
    sensor: '',
    switch: '',
    condition: '',
    threshold_value: 0,
    description: ''
  });

  useEffect(() => {
    if (user && user.business && user.business.id) {
      setRule(prevRule => ({
        ...prevRule,
        account: user.business.id
      }));
    }
  }, [user]);

  useEffect(() => {
    if (ruleId) {
      setEdit(true);
    }
  }, [ruleId]);

  useEffect(() => {
    if (edit && ruleData) {
      setRule(prevRule=>({
        account:ruleData.account??prevRule.account,
        name:ruleData.name??prevRule.name,
        sensor: ruleData.sensor??prevRule.sensor,
        switch: ruleData.switch??prevRule.switch,
        condition: ruleData.condition??prevRule.condition,
        threshold_value: ruleData.threshold_value??prevRule.threshold_value,
        description: ruleData.description??prevRule.description
      }));
    }
  }, [edit, ruleData]);
    

  const { data: sensors, isLoading, isError } = useQuery({
    queryFn: listSensor,
    queryKey: ['listSensors'],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setRule({ ...rule, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    edit? mutate({rule, ruleId}): mutate({rule});
  };
    

  if (isLoading || isPending || isLoadingRule) return <Spinner />;
  if (isError || isErrorRule) return <h2 className='flex justify-center text-red-600'>API Error, Try again.</h2>;

  return (
    <div className="flex justify-center lg:justify-start lg:pl-4">
      
      <form className="w-full max-w-md mx-4 my-4 p-4 bg-white rounded shadow-md lg:mx-0" onSubmit={handleSubmit}>
        <h2 className="text-xl font-bold mb-5">Add rule</h2>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={rule.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700">Description</label>
          <input
            type="text"
            id="description"
            name="description"
            value={rule.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="sensor" className="block text-gray-700">Sensor</label>
          <select
            id="sensor"
            name="sensor"
            value={rule.sensor}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled>Select sensor</option>
            {sensors?.map((sensor) => (
              <option key={sensor.id} value={sensor.id}>
                {sensor.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="condition" className="block text-gray-700">Condition</label>
          <select
            id="condition"
            name="condition"
            value={rule.condition}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled>Select condition</option>
            <option value="gt">Greater</option>
            <option value="lt">Lower</option>
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="threshold" className="block text-gray-700">Threshold</label>
          <input
            type="number"
            id="threshold"
            name="threshold_value"
            value={rule.threshold_value}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateRuleForm;
