import axios, { AxiosError } from 'axios';
import { parseApiError } from './util/error';
import { ApiResponse, CustomAxiosRequestConfig } from './util/apiTypes';
import { getUnauthEndpoints } from './util/apiUtils';
import appConfig from '../config';


const baseApiUrl = appConfig.apiBaseUrl;

const api = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const noAuthRequiredEndpoints = getUnauthEndpoints();

api.interceptors.request.use(
  (config) => {
    if (config.url && !noAuthRequiredEndpoints.includes(config.url)) {
      const token = localStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add the interceptor to the Axios instance
api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<ApiResponse>) => {
    if (error.response && error.config) {
      error.message = parseApiError(error);
      const originalRequest: CustomAxiosRequestConfig = error.config as CustomAxiosRequestConfig; // Cast to your custom type
      if (error.response.status === 401 && !originalRequest._retry && originalRequest.url && !noAuthRequiredEndpoints.includes(originalRequest.url)) {
        originalRequest._retry = true;
        return refreshAccessToken(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

// function to handle the refresh token logic
async function refreshAccessToken(originalRequest: CustomAxiosRequestConfig) {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    // No valid refresh token, perform necessary cleanup
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
    return Promise.reject(new Error('No refresh token available'));
  }

  try {
    const { data } = await axios.post(`${baseApiUrl}/auth/refresh_token`, { refresh_token: refreshToken });
    localStorage.setItem('accessToken', data.data.access_token);
    localStorage.setItem('refreshToken', data.data.refresh_token);
    api.defaults.headers.common['Authorization'] = `Bearer ${data.data.accessToken}`;

    // Ensure headers are defined on the original request
    if (!originalRequest.headers) {
      originalRequest.headers = {};
    }
    originalRequest.headers['Authorization'] = `Bearer ${data.data.accessToken}`;

    return api(originalRequest);
  } catch (refreshError: unknown) {
    if (axios.isAxiosError(refreshError)) {
      if (refreshError.response && refreshError.response.status === 401) {
        // Clear local storage or any other cleanup if needed
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      
        // Redirect to login page
        window.location.href = '/login';
      }
      return Promise.reject(refreshError);
    } else {
      // Handle non-Axios errors here if necessary
      console.error('An unexpected error occurred:', refreshError);
      return Promise.reject(new Error('An unexpected error occurred'));
    }
  }
}
export default api;
