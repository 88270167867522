import React from 'react';
import { IconType } from 'react-icons';
import { useAppDispatch } from '../../store/hooks';
import { closeSidebar } from '../../features/sidebar/sidebarSlice';

interface SidebarItemProps {
  icon: IconType;
  label: string;
  isActive: boolean;
  onClick?: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon: Icon, label, isActive, onClick }) => {
  const dispatch = useAppDispatch();

  const handleClick = ()=>{
    dispatch(closeSidebar());
    onClick?.();
  };
  return (

    <div
      className={`flex items-center p-2 cursor-pointer ${isActive ? 'bg-gray-200 text-blue-600 font-bold' : 'hover:bg-gray-200 text-gray-700'}`}
      onClick={handleClick}
    >
      <Icon className='w-3 h-3' />
      <span className='ml-4'>{label}</span>
    </div>
  );};

export default SidebarItem;
