import { useQuery } from '@tanstack/react-query';
import GroupBreadcrumb from './GroupBreadcrumb';
import GroupTable from './GroupTable';
import { getGroupChildrenAncestor, getGroupRoot } from '../../api/group/groupApi';
import Spinner from '../Spinner';
import { GroupDetailType, GroupType } from '../../api/util/apiTypes';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import GroupAddModal from './GroupAddModal';
import AddDeviceModal from '../device/AddDeviceModal';
import DeviceListCard from '../device/DeviceListCard';

const Group = () => {

  // Group Modal setup
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Add Group');
  const [initialData, setInitialData] = useState<{ id: string; name: string; description: string } | undefined>(undefined);
  
  const openGroupModal = (data?: { id: string; name: string; description: string }) => {
    setInitialData(data);
    setModalTitle(data ? 'Update Group' : 'Add Group');
    setIsModalOpen(true);
  };
  
  const closeGroupModal = () => {
    setIsModalOpen(false);
    setInitialData(undefined);
  };
  
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const openDeviceModal = ()=>{
    setIsDeviceModalOpen(true);
  };
  const closeDeviceModal = ()=>{
    setIsDeviceModalOpen(false);
  };
  
  
  const { groupId } = useParams<{ groupId?: string }>(); 
  const breadcrumbItems: { label: string; link?: string }[] = [];
  const tableGroups: { id: string; name: string; description: string; link?: string; }[] = [];

  const { data: rootData, isLoading: rootLoading, error: rootError } = useQuery<GroupType, AxiosError>({
    queryKey: ['groupRoot'],
    queryFn: getGroupRoot,
  });

  const rootId = rootData ? rootData.id : undefined;
  const currentGroupId: string | undefined = groupId ? groupId : rootId;

  const { data: groupDetail, isLoading: groupLoading, error: groupError } = useQuery<GroupDetailType, AxiosError>({
    queryKey: ['childrenAncestors', currentGroupId],
    queryFn: () => getGroupChildrenAncestor(currentGroupId),
    enabled: !!currentGroupId,
  });

  if (rootLoading || groupLoading) return <Spinner />;
  if (rootError instanceof Error) return <p className="text-center text-red-500">Error: {rootError.message}</p>;
  if (groupError instanceof Error) return <p className="text-center text-red-500">Error: {groupError.message}</p>;

  const children = groupDetail?.children;
  const ancestors = groupDetail?.ancestors;
  const devices = groupDetail?.devices;

  children?.forEach(child => {
    tableGroups.push({
      id: child.id,
      name: child.name,
      description: child.description,
      link: `/group/${child.id}`,
    });
  });

  ancestors?.forEach(ancestor => {
    breadcrumbItems.push({ label: ancestor.name, link: `/group/${ancestor.id}` });
  });

  const headingDivClass = 'flex items-center justify-between mt-6 mb-4';
  const headerClass = 'text-gray-500 text-xl font-bold';
  const buttonClass = 'text-white px-4 py-2 rounded-md';

  return (
    <div className="container mx-auto p-4">
      <GroupBreadcrumb items={breadcrumbItems} />
      <div className='flex items-center justify-end mt-6 mb-4 gap-4'>
        <button className={`bg-sky-500 hover:bg-sky-600 ${buttonClass}`} onClick={() => openGroupModal()}>
          Add Group
        </button>
        <button className={`bg-amber-500 hover:bg-amber-600 ${buttonClass}`} onClick={openDeviceModal}>
          Add Device
        </button>
      </div>
      <div className={tableGroups.length > 0 ? 'block' : 'hidden'}>
        <div className={headingDivClass}>
          <h1 className={headerClass}>Groups</h1>
        </div>
        <GroupTable groups={tableGroups} onEdit={openGroupModal} />
      </div>



      {/* Display Devices */}
      {devices && devices.length > 0 && (
        <div>
          <div className={headingDivClass}>
            <h1 className={headerClass}>Devices</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {devices.map((device) => (
              <DeviceListCard key={device.id} device={device} />
            ))}
          </div>
        </div>
      )}

      <GroupAddModal
        isOpen={isModalOpen}
        onClose={closeGroupModal}
        title={modalTitle}
        parent={currentGroupId ?? ''}
        initialData={initialData}
      />
      <AddDeviceModal
        isOpen={isDeviceModalOpen}
        onClose={closeDeviceModal}
        group={currentGroupId?? ''}
      />
    </div>
  );
};

export default Group;
