// // src/components/Notification.tsx
// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { RootState } from '../store/store';
// import { hideNotification } from '../features/notificationSlice';

// const Notification: React.FC = () => {
//   const dispatch = useDispatch();
//   const notification = useSelector((state: RootState) => state.notification);

//   useEffect(() => {
//     if (notification.type) {
//       const timer = setTimeout(() => {
//         dispatch(hideNotification());
//       }, notification.duration);

//       return () => clearTimeout(timer);
//     }
//   }, [notification, dispatch]);

//   if (!notification.type) return null;

//   const typeStyles = {
//     success: 'bg-green-100 border-green-500 text-green-700',
//     error: 'bg-red-100 border-red-500 text-red-700',
//     info: 'bg-blue-100 border-blue-500 text-blue-700',
//     warning: 'bg-yellow-100 border-yellow-500 text-yellow-700',
//   };

//   return (
//     <div
//       className={`fixed top-4 right-0 transform translate-x-full border-l-4 p-4 ${typeStyles[notification.type]} rounded-md transition-transform duration-500 ease-in-out`}
//       role="alert"
//       style={{ animation: 'slide-in 0.5s forwards, slide-out 0.5s forwards 4.5s' }} // Adjust timing as needed
//     >
//       <p className="font-bold">{notification.type.charAt(0).toUpperCase() + notification.type.slice(1)}</p>
//       <p>{notification.message}</p>
//     </div>
//   );
// };

// export default Notification;


// src/components/Notification.tsx
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/store';
import { hideNotification } from '../features/notificationSlice';

const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state: RootState) => state.notification);
  const [animationClass, setAnimationClass] = useState('slide-in');

  useEffect(() => {
    if (notification.type) {
      setAnimationClass('slide-in');
      const hideTimer = setTimeout(() => {
        setAnimationClass('slide-out');
      }, notification.duration? notification.duration - 500: notification.duration); // Start slide-out animation before hiding

      const removeTimer = setTimeout(() => {
        dispatch(hideNotification());
      }, notification.duration); // Ensure this is slightly longer than the duration of the slide-out animation

      return () => {
        clearTimeout(hideTimer);
        clearTimeout(removeTimer);
      };
    }
  }, [notification, dispatch]);

  if (!notification.type) return null;

  const typeStyles = {
    success: 'bg-green-100 border-green-500 text-green-700',
    error: 'bg-red-100 border-red-500 text-red-700',
    info: 'bg-blue-100 border-blue-500 text-blue-700',
    warning: 'bg-yellow-100 border-yellow-500 text-yellow-700',
  };

  return (
    <div
      className={`fixed top-4 right-4 transform border-l-4 p-4 ${typeStyles[notification.type]} rounded-md ${animationClass}`}
      role="alert"
      style={{
        animation: `${animationClass} 0.5s forwards`,
      }}
    >
      <p className="font-bold">{notification.type.charAt(0).toUpperCase() + notification.type.slice(1)}</p>
      <p>{notification.message}</p>
    </div>
  );
};

export default Notification;

