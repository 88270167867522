import { Outlet } from 'react-router-dom';
import TechSupportNavbar from './TechSupportNavbar';

const TechSupport: React.FC = ()=>{
  return (
    <div>
      <TechSupportNavbar />
      <Outlet />
    </div>

  );
};

export default TechSupport;