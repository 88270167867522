import DeviceStatus from '../components/dashboard/DeviceStatus';


const HomePage: React.FC = ()=>{

  return (
    <div className="p-4">
      {/* Top section with 3 columns
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div className="bg-white p-6 rounded-lg shadow-md">Card 1</div>
        <div className="bg-white p-6 rounded-lg shadow-md">Card 2</div>
        <div className="bg-white p-6 rounded-lg shadow-md">Card 3</div>
      </div> */}
      {/* Middle section with 2 columns */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Row 1 Card 1 */}
        <div >
          <DeviceStatus
          />
        </div>

        {/* Row 1 Card 2 */}
        <div >
          {/* <DeviceStatus
            totalDevices={200}
            onlineDevices={140}
            onlinePercentage={70}
            offlineDevices={60}
            offlinePercentage={30}
          /> */}
        </div>
      </div>
      {/* Bottom section with 1 column */}
      {/* <div className="grid grid-cols-1 gap-4">
        <div className="bg-white p-6 rounded-lg shadow-md">Card 6</div>
      </div> */}
    </div>

  );
};

export default HomePage;