import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { removeDevice } from '../../api/device/deviceApi';
import { useNavigate } from 'react-router-dom';

interface RemoveModalProps {
  isOpen: boolean;
  onClose: () => void;
  deviceId: string;
  title: string;
}

const DeviceRemoveModal: React.FC<RemoveModalProps> = ({ isOpen, onClose, deviceId, title }) => {
  const [confirmationText, setConfirmationText] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: removeDevice,
    onSuccess: () => {
      onClose();
    },
    onError: (error: AxiosError) => {
      setErrorMessage(error.message);
    },
  });

  const handleDelete = () => {
    mutation.mutate(deviceId);
    navigate(-1);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4 sm:px-0">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full sm:w-auto mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            &times;
          </button>
        </div>
        <p className="mb-4">
            You are about to remove a device. To add the device again, you will need the Serial Number and Secret Code. Please type {'"REMOVE"'} to confirm.
        </p>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <input
          type="text"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          placeholder="Type REMOVE to confirm"
          className="mb-4 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
        />
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onClose}
            className="mr-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            disabled={confirmationText !== 'REMOVE' || mutation.isPending}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeviceRemoveModal;
