// src/store/notificationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationState {
  type: 'success' | 'error' | 'info' | 'warning' | null;
  message: string;
  duration?: number;
}

const initialState: NotificationState = {
  type: null,
  message: '',
  duration: 5000,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification(state, action: PayloadAction<NotificationState>) {
      return action.payload;
    },
    hideNotification(state) {
      state.type = null;
      state.message = '';
      state.duration = 5000;
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
