import { Link } from 'react-router-dom';
import { DeviceType } from '../../api/util/apiTypes';

interface DeviceCardProps {
  device: DeviceType;
}

const DeviceListCard: React.FC<DeviceCardProps> = ({ device }) => {
  const isOnline = device?.status==='Online';
  return (
    <div className="border rounded-md p-4 shadow-sm hover:shadow-md transition-shadow responsive-text-xs">
      <p className="text-lg font-semibold">
        <Link to={`/device/${device.id}`} className="text-sky-500 hover:text-sky-800">
          {device.name? device.name: '-- no device name --'}
        </Link>
      </p>
      <p>Type: {device.type}</p>
      <p>Status: <span className={`inline-block h-2.5 w-2.5 rounded-full mr-1 ml-3 ${isOnline ? 'bg-green-500' : 'bg-red-500'}`} />
        {isOnline ? 'Online' : 'Offline'}</p>
    </div>
  );
};

export default DeviceListCard;
