import React from 'react';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export interface BreadcrumbProps {
  items: { label: string; link?: string }[];
}

const GroupBreadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label="breadcrumb" className="p-4 bg-white rounded shadow-sm">
      <ol className="flex space-x-2 text-gray-500 responsive-text-xs">
        <li className="flex items-center">
          <Link to='/group'>
            <FaHome className="hover:text-gray-700"/>
          </Link>
          
          {/* </a> */}
        </li>
        {items.map((item, index) => (
          <li key={index} className="flex items-center">
            <span className="mx-2">{'>'}</span>
            {item.link ? (
              <Link to={item.link} className="hover:text-gray-700">
                {item.label}
              </Link>
            ) : (
              <span>{item.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default GroupBreadcrumb;
