// errorHandler.ts
import { AxiosError } from 'axios';
import { ApiResponse, ApiError } from './apiTypes';

// Function to parse and generate error messages based on error response from API
export const parseApiError = (error: AxiosError<ApiResponse>): string => {
  if (error.response && error.response.data && error.response.data.error) {
    const apiError: ApiError = error.response.data.error;
    switch (apiError.type) {
    case 'validation_error':
      return `${apiError.detail}: ${apiError.attr || 'Unknown attribute'}`;
    case 'authentication_error':
      return `Authentication failed: ${apiError.detail}`;
      // Add more cases as needed for different error types
    default:
      return apiError.detail || 'An unknown error occurred';
    }
  }

  // Fallback error message if no detailed error is provided
  return error.message || 'Network error';
};
