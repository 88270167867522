// src/components/Navbar.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FaAngleDown } from 'react-icons/fa';

interface DropdownState {
  [key: string]: boolean;
}

const menuLink = {
  dashboard: '/tech-support',
  addDevice: '/tech-support/add-device',
  searchDevice: '/tech-support/search-device',
  complains: '/tech-support',
  createTicket: '/tech-support',
  addSensor: '/tech-support/add-sensor',
  searchSensor: '/tech-support/search-sensor',
  addSwitch: '/tech-support/add-switch',
  searchSwitch: '/tech-support/search-switch',
};


const TechSupportNavbar: React.FC = () => {

  // const [isDeviceDropdownOpen, setIsDeviceDropdownOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsDeviceDropdownOpen(!isDeviceDropdownOpen);
  // };
  const [dropdowns, setDropdowns] = useState<DropdownState>({});

  const toggleDropdown = (id: string) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleNewJobClick = () => {
    // Dispatch an action or handle the click event
    // dispatch(yourAction());
  };

  
  return (
    <nav className="bg-white border-b border-gray-200 px-4 py-2 flex justify-between items-center">
      <div className="flex space-x-8">
        <Link to={menuLink.dashboard} className="hidden md:block text-black font-medium hover:text-blue-500">
          Dashboard
        </Link>

        <div className="relative hidden md:block">
          <button
            onClick={()=>toggleDropdown('device')}
            className="flex items-center text-gray-500 font-medium hover:text-blue-500 focus:outline-none"
          >
            Device 
            <FaAngleDown />
          </button>
          {dropdowns['device'] && (
            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
              <Link
                to={menuLink.addDevice}
                onClick={()=>toggleDropdown('device')}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Add Device
              </Link>
              <Link
                to={menuLink.searchDevice}
                onClick={()=>toggleDropdown('device')}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Search Device
              </Link>
            </div>
          )}
        </div>

        <div className="relative hidden md:block">
          <button
            onClick={()=>toggleDropdown('sensor')}
            className="flex items-center text-gray-500 font-medium hover:text-blue-500 focus:outline-none"
          >
            Sensor 
            <FaAngleDown />
          </button>
          {dropdowns['sensor'] && (
            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
              <Link
                to={menuLink.addSensor}
                onClick={()=>toggleDropdown('sensor')}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Add Sensor
              </Link>
              <Link
                to={menuLink.searchSensor}
                onClick={()=>toggleDropdown('sensor')}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Search Device
              </Link>
            </div>
          )}
        </div>

        <div className="relative hidden md:block">
          <button
            onClick={()=>toggleDropdown('switch')}
            className="flex items-center text-gray-500 font-medium hover:text-blue-500 focus:outline-none"
          >
            Switch 
            <FaAngleDown />
          </button>
          {dropdowns['switch'] && (
            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
              <Link
                to={menuLink.addSwitch}
                onClick={()=>toggleDropdown('switch')}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Add Switch
              </Link>
              <Link
                to={menuLink.searchSwitch}
                onClick={()=>toggleDropdown('switch')}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                Search Switch
              </Link>
            </div>
          )}
        </div>

        
        <Link to={menuLink.complains} className="hidden md:block text-gray-500 font-medium hover:text-blue-500">
          Complains
        </Link>




      </div>
      <div className="flex items-center space-x-4">
        <Link to={menuLink.createTicket}>
          <button
            onClick={handleNewJobClick}
            className="hidden md:block bg-purple-600 text-white font-medium py-2 px-4 rounded hover:bg-purple-700 transition"
          >
          + Create Ticket
          </button>
        </Link>
        <Menu as="div" className="relative inline-block text-left md:hidden">
          <div>
            <MenuButton
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            >
              Menu
            </MenuButton>
          </div>
          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <MenuItem>
                  {({ focus }) => (
                    <Link
                      to={menuLink.dashboard}
                      className={`${
                        focus ? 'bg-gray-100' : 'text-gray-700'
                      } block px-4 py-2 text-sm`}
                    >
                      Dashboard
                    </Link>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <Link
                      to={menuLink.addDevice}
                      className={`${
                        focus ? 'bg-gray-100' : 'text-gray-700'
                      } block px-4 py-2 text-sm`}
                    >
                      Add Device
                    </Link>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <Link
                      to={menuLink.searchDevice}
                      className={`${
                        focus ? 'bg-gray-100' : 'text-gray-700'
                      } block px-4 py-2 text-sm`}
                    >
                      Search Device
                    </Link>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <Link
                      to={menuLink.complains}
                      className={`${
                        focus ? 'bg-gray-100' : 'text-gray-700'
                      } block px-4 py-2 text-sm`}
                    >
                      Complains
                    </Link>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <Link
                      to={menuLink.createTicket}
                      className={`${
                        focus ? 'bg-gray-100' : 'text-gray-700'
                      } block px-4 py-2 text-sm`}
                    >
                      Add Ticket
                    </Link>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </nav>
  );
};




export default TechSupportNavbar;
