import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import api from '../api/http';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { setUser } from '../features/user/userSlice';
import { AxiosError } from 'axios';
import { UserType } from '../api/util/apiTypes';

const fetchUsers = async () => {
  const { data } = await api.get<{ data: UserType }>('/users');
  return data.data; // Ensure this matches your API response structure
};

const User: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  const { data, error, isLoading } = useQuery<UserType, AxiosError>({
    queryKey: ['user'],
    queryFn: fetchUsers,
  });

  // Use useEffect to dispatch the setUser action only when `data` changes
  useEffect(() => {
    if (data) {
      dispatch(setUser(data));
    }
  }, [data, dispatch]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;
  if (!data) return <div>Loading...</div>;

  return (
    <ul>
      <li key={user.id}>This is new page: {user.email}</li>
    </ul>
  );
};

export default User;
