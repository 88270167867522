import endpoints from '../endpoints';
import api from '../http';
import { GroupDetailType } from '../util/apiTypes';

export const getGroupChildrenAncestor = async (group_id: string|undefined): Promise<GroupDetailType> =>{
  let url = endpoints.groups.getChildren.path;
  url = group_id? url.replace(':id', group_id): url;
  const { data } = await api.get(url);
  return data.data; 
};
  
export const getGroupRoot = async () =>{
  const url = endpoints.groups.getRoot.path;
  const { data } = await api.get(url);
  return data.data; 
};

export const addGroup = async (groupData: {name: string; description: string; parent: string}) =>{
  const url = endpoints.groups.add.path;
  const { data } = await api.post(url, groupData);
  return data.data; 
};

export const updateGroup = async (groupData: {name: string; description: string; parent: string}, group_id: string) =>{
  let url = endpoints.groups.getUpdate.path;
  url = group_id? url.replace(':id', group_id): url;
  const { data } = await api.put(url, groupData);
  return data.data; 
};
  
export const deleteGroup = async (group_id: string) =>{
  let url = endpoints.groups.getUpdate.path;
  url = group_id? url.replace(':id', group_id): url;
  const { data } = await api.delete(url);
  return data.data; 
};