import logo from './logo.png'; // Make sure the path points to your PNG file

const Logo: React.FC = () => {
  return (
    <div className="w-32 sm:w-24 md:w-32 lg:w-40 xl:w-48">
      <img src={logo} alt="Website Logo" className="w-full h-auto" />
    </div>
  );
};

export default Logo;
