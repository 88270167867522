import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { CreateTriggerType} from '../../api/util/apiTypes';
import { createTrigger, getTrigger, updateTrigger } from '../../api/rule/triggerApi';
import { listSwitch } from '../../api/switch/swicthApi';

const updateTriggerObject = (obj: CreateTriggerType) => {
  const { trigger_type, ...rest } = obj;

  if (trigger_type === 'switch') {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { email, ...updatedObj } = rest;
    return { ...updatedObj, trigger_type };
  } else if (trigger_type === 'email') {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { switch: switchValue, action, ...updatedObj } = rest;
    return { ...updatedObj, trigger_type };
  }

  return obj; // Return the object as is if trigger_type is neither 'switch' nor 'email'
};

const TriggerForm: React.FC = () => {

  const { ruleId, triggerId } = useParams<{ ruleId: string, triggerId: string }>(); 

  const [edit, setEdit] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleShowError = (error: string) => {
    dispatch(showNotification({ type: 'error', message: error, duration: 3000 }));
  };

  const handleShowSuccess = () => {
    dispatch(showNotification({ type: 'success', message: edit?'Trigger updated successfully!':'New Trigger created successfully!', duration: 3000 }));
  };


  const { data: triggerData, isLoading: isLoadingTrigger, isError: isErrorTrigger } = useQuery({
    queryFn: ()=>getTrigger(triggerId?triggerId:''),
    queryKey: ['trigger', triggerId],
    enabled: !!triggerId,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (variables: { triggerData: object; triggerId?: string }) => {
      if (edit && variables.triggerId) {
        return updateTrigger(variables.triggerData, variables.triggerId);
      } else {
        return createTrigger(variables.triggerData);
      }
    },
    onSuccess: () => {
      handleShowSuccess();
      navigate(-1);
    },
    onError: (error) => {
      handleShowError(error.message);
    }
  });

  const [trigger, setTrigger] = useState<CreateTriggerType>({
    rule: '',
    trigger_type: '',
    switch: '',
    email: '',
    action: '',
  });

  useEffect(() => {
    if (ruleId) {
      
      setTrigger(prevTrigger => ({
        ...prevTrigger,
        rule: ruleId
      }));
    }
  }, [ruleId]);
  
  useEffect(() => {
    if (triggerId) {
      setEdit(true);
    }
  }, [triggerId]);
  
  useEffect(() => {
    if (edit && triggerData) {
      setTrigger({
        rule: triggerData.rule ?? '',
        trigger_type: triggerData.trigger_type ?? '',
        switch: triggerData.switch ?? '',
        email: triggerData.email ?? '',
        action: triggerData.action ?? '',
      });
    }
  }, [edit, triggerData]);
    

  const { data: switches, isLoading, isError } = useQuery({
    queryFn: listSwitch,
    queryKey: ['listSwitch'],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setTrigger({ ...trigger, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const triggerData = updateTriggerObject(trigger);
    edit && triggerId? mutate({triggerData, triggerId}): mutate({triggerData});
  };
    
    

  if (isLoading || isPending || isLoadingTrigger) return <Spinner />;
  if (isError || isErrorTrigger) return <h2 className='flex justify-center text-red-600'>API Error, Try again.</h2>;

  return (
    <div className="flex justify-center lg:justify-start lg:pl-4">
      
      <form className="w-full max-w-md mx-4 my-4 p-4 bg-white rounded shadow-md lg:mx-0" onSubmit={handleSubmit}>
        <h2 className="text-xl font-bold mb-5">Add Trigger</h2>

        <div className="mb-4">
          <label htmlFor="trigger_type" className="block text-gray-700">Trigger Type</label>
          <select
            id="trigger_type"
            name="trigger_type"
            value={trigger.trigger_type}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled>Select Trigger Type</option>
            <option value="switch">Switch</option>
            <option value="email">Email</option>
          </select>
        </div>

        {trigger.trigger_type == 'switch' && <div className="mb-4">
          <label htmlFor="switch" className="block text-gray-700">Switch</label>
          <select
            id="switch"
            name="switch"
            value={trigger.switch}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
          >
            <option value="" disabled>Select switch</option>
            {switches?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>}

        {trigger.trigger_type == 'switch' && <div className="mb-4">
          <label htmlFor="action" className="block text-gray-700">Action</label>
          <select
            id="action"
            name="action"
            value={trigger.action}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
          >
            <option value="" disabled>Select Action</option>
            <option value="on">On</option>
            <option value="off">Off</option>
          </select>
        </div>}

        {trigger.trigger_type == 'email' && <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={trigger.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded"
          />
        </div>}

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default TriggerForm;
