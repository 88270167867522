import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getDevicesStatus } from '../../api/device/deviceApi';
import { DevicesStatus } from '../../api/util/apiTypes';
import Spinner from '../Spinner';



const DeviceStatus: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['devicesStatus'],
    queryFn: getDevicesStatus
  });

  if (isLoading) return <Spinner />;
  if (error instanceof Error) return <p className="text-center text-red-500">Error: {error.message}</p>;

  const { total, online, online_percent, offline, offline_percent } = data as DevicesStatus;

  return (
    <div className="bg-white shadow p-4 rounded-lg mx-auto  min-w-0">
      <h2 className="text-lg font-semibold text-gray-800">Total Devices</h2>
      <div className="text-3xl font-bold text-green-600 my-3">{total} <span className="text-sm font-normal text-green-500">↑ {online_percent}%</span></div>
              
      <div className="space-y-4 mt-6">
        <div>
          <p className="text-gray-800">Device Online</p>
          <div className="flex justify-between items-center">
            <div className="h-1 w-full bg-gray-300 rounded">
              <div className="h-full bg-green-500 rounded" style={{width: `${online_percent}%`}}></div>
            </div>
            <span className="text-green-500 ml-3">{online} ↑ {online_percent}%</span>
          </div>
        </div>
        <div>
          <p className="text-gray-800">Device Offline</p>
          <div className="flex justify-between items-center">
            <div className="h-1 w-full bg-gray-300 rounded">
              <div className="h-full bg-red-500 rounded" style={{width: `${offline_percent}%`}}></div>
            </div>
            <span className="text-red-500 ml-3">{offline} ↓ {offline_percent}%</span>
          </div>
        </div>
      </div>
    </div>
  );
};
  

export default DeviceStatus;
