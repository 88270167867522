import React, { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';
import { CreateSensorType } from '../../api/util/apiTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { createSensor, getSensorTypes } from '../../api/sensor/sensorApi';

const CreateSensorForm: React.FC = () => {

  const {deviceId} = useParams();

  // Notification related setup
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleShowError = (error: string) => {
    dispatch(showNotification({ type: 'error', message: error, duration: 3000 }));
  };

  const handleShowSuccess = () => {
    dispatch(showNotification({ type: 'success', message: 'New device created successfully!', duration: 3000 }));
  };

  // Create device setup

  const {mutate, isPending} = useMutation({
    mutationFn: createSensor,
    onSuccess:(data)=>{
      handleShowSuccess();
      navigate(`/device/${data.device}`);
    },
    onError:(error)=>{
      handleShowError(error.message);
    }
  });
  const [sensor, setSensor] = useState<CreateSensorType>({
    name: '',
    type: '',
    unit: '',
    description: '',
    device: deviceId?deviceId:''
  });

  // Get sensor tyoes
  const {data, isLoading, isError} = useQuery({
    queryFn:getSensorTypes,
    queryKey: ['sensorTypes'],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSensor({ ...sensor, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    mutate(sensor);
  };

  if (isLoading || isPending) return <Spinner />;
  if (isError) return <h2 className='flex justify-center  text-red-600'>API Error, Try again.</h2>;

  return (

    <form className="max-w-md mx-auto p-4 bg-white rounded shadow-md" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={sensor.name}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="type" className="block text-gray-700">Type</label>
        <select
          id="type"
          name="type"
          value={sensor.type}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        >
          <option value="" disabled>Select sensor type</option>
          {data?.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="unit" className="block text-gray-700">Unit</label>
        <input
          type="text"
          id="unit"
          name="unit"
          value={sensor.unit}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>
      {/* DEVICE */}
      <div className="mb-4">
        <label htmlFor="device" className="block text-gray-700">Device Id</label>
        <input
          type="text"
          id="device"
          name="device"
          disabled={!!deviceId}
          value={sensor.device}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="description" className="block text-gray-700">Description</label>
        <textarea
          id="description"
          name="description"
          value={sensor.description}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
        >
          Submit
        </button>
      </div>
    </form>

  );
};

export default CreateSensorForm;
