import { useQuery } from '@tanstack/react-query';
import GenericDetail from '../generic/GenericDetail';
import { getRule } from '../../api/rule/ruleApi';
import { useParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { listSensor } from '../../api/sensor/sensorApi';
import GenericTable from '../generic/GenericTable';
import { deleteTrigger, listTriggerRule } from '../../api/rule/triggerApi';
import { listSwitch } from '../../api/switch/swicthApi';

const cssClass = {
  title: 'text-lg font-medium leading-6 text-gray-900',
  value: 'mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0'
};


const RuleDetail: React.FC = () => {

  const { ruleId } = useParams<{ ruleId: string }>(); 

  const { data: sensors, isLoading: isLoadingSensors, isError: isErrorSensors } = useQuery({
    queryFn: listSensor,
    queryKey: ['listSensors'],
  });

  const { data: switches, isLoading: isLoadingSwitches, isError: isErrorSwitches } = useQuery({
    queryFn: listSwitch,
    queryKey: ['listSwitch'],
  });

  const { data, isLoading, isError } = useQuery({
    queryFn: ()=>getRule(ruleId?ruleId:''),
    queryKey: ['detailData', ruleId],
  });

  const { data: triggersData, isLoading: isLoadingTrigger, isError: isErrorTrigger } = useQuery({
    queryFn: ()=>listTriggerRule(ruleId?ruleId:''),
    queryKey: ['tableData', ruleId],
  });

  const config = {
    title: data?.name,
    description: data?.description
  };

  const getSensorName = (sensorId: string | undefined) => {
    const sensor = sensors?.find(sensor => sensor.id === sensorId);
    return sensor ? sensor.name : 'Unknown Sensor';
  };
    
  const getCondition = (condition: string | undefined) => {
    if (condition === 'gt') return 'Greater than';
    else if (condition === 'lt') return 'Less than';
    else return '';
  };
  const getSwitchName = (switchId: string | undefined) => {
    const _switch = switches?.find(_switch => _switch.id === switchId);
    return _switch ? _switch.name : 'Unknown Switch';
  };

  const getReadableDate = (rawDate: string) => {
    const date = new Date(rawDate);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    }); // Format based on user's locale
  };

  const ruleData = [
    {key: 'Sensor', value: getSensorName(data?.sensor)??''},
    {key: 'Threshold Value', value: data?.threshold_value??''},
    {key: 'Condition', value: getCondition(data?.condition)??''},
    {key: 'Description', value: data?.description??''},
    {key: 'Triggers', value: data?.trigger_count??''},
  ];

  const columns = [
    {header: 'Trigger Type', key: 'trigger_type'},
    {header: 'Switch', key: 'switch'},
    {header: 'Email', key: 'email'},
    {header: 'Action', key: 'action'},
    {header: 'Last email sent', key: 'last_email_sent'}
  ];

  const tableData = triggersData
    ?.filter(trigger => trigger !== null && trigger !== undefined)
    .map(trigger => ({
      ...trigger,
      switch: trigger.switch ? getSwitchName(trigger.switch) : '',
      last_email_sent: trigger.last_email_sent ? getReadableDate(trigger.last_email_sent) : '',
      email: trigger.email ?? '',
      action: trigger.action ?? '', 
    }));


  const tableConfig = {
    addButtonTitle: 'Add Trigger',
    addNewRoute:`/trigger/add-new/${ruleId}`,
    deleteApi: deleteTrigger,
    baseRoute: '/trigger'
  };

  if (isLoading || isLoadingSensors || isLoadingSwitches || isLoadingTrigger) return <Spinner />;
  if (isError || isErrorSensors || isErrorSwitches || isErrorTrigger) return <h2 className='flex justify-center text-red-600'>API Error, Try again.</h2>;

  return (
    <div>
      {data? <GenericDetail data={ruleData} config={config}/> : <Spinner />}
      <div className='p-3 px-4 py-2'>
        {data && <h1 className={`${cssClass.title} mt-6 mb-1 border-b border-gray-300 pb-2`}>Triggers:</h1>}
      </div>
      {tableData? <GenericTable data={tableData} columns={columns} config={tableConfig} /> : 'No Triggers to display.'}
      
    </div>
  );
};

export default RuleDetail;