import { useParams } from 'react-router-dom';
import DeviceDetail from '../components/device/DeviceDetail';
import DeviceList from '../components/device/DeviceList';

const DevicePage: React.FC = () => {
  const { deviceId } = useParams<{ deviceId?: string }>(); // Get deviceId from route parameters

  return (
    <div className='p-3'>
      {deviceId ? <DeviceDetail deviceId={deviceId} /> : <DeviceList />}
    </div>
  );
};
export default DevicePage;