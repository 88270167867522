import endpoints from '../endpoints';
import api from '../http';
import { CreateSensorType, SensorForDeviceType, SensorType, SensorTypeType } from '../util/apiTypes';


export const getSensorForDevice = async (deviceId: string): Promise<SensorForDeviceType[]>=>{
  let url = endpoints.sensors.getSensorForDevice.path;
  url = url.replace(':device_id', deviceId);
  const { data } = await api.get<{ data: SensorForDeviceType[] }>(url);
  return data.data;
};

export const getSensorTypes = async (): Promise<SensorTypeType[]>=>{
  const url = endpoints.sensors.sensorType.path;
  const {data} = await api.get(url);
  return data.data;
};

export const createSensor = async (sensorData: CreateSensorType): Promise<SensorType>=>{
  const url = endpoints.sensors.sensor.path;
  const { data } = await api.post<{ data: SensorType }>(url, sensorData);
  return data.data;
};

export const listSensor = async (): Promise<SensorType[]>=>{
  const url = endpoints.sensors.sensor.path;
  const {data} = await api.get(url);
  return data.data;
};