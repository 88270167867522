import React from 'react';
import { useNavigate } from 'react-router-dom';

const FallbackPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center">
      <h1 className="text-6xl font-bold text-red-500 mb-4">Oops!</h1>
      <p className="text-xl text-gray-600 mb-8">Something went wrong. Please try again later.</p>
      <button
        onClick={handleGoHome}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
      >
        Go to HomePage
      </button>
    </div>
  );
};

export default FallbackPage;
