import { DeviceType, SensorForDeviceType, SwitchType, UserType } from '../../api/util/apiTypes';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getDeviceDetail } from '../../api/device/deviceApi';
import Spinner from '../Spinner';
import SensorCard from '../sensor/SensorCard';
import { getSensorForDevice } from '../../api/sensor/sensorApi';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { getSwitchForDevice } from '../../api/switch/swicthApi';
import SwitchCard from '../switch/SwitchCard';
import { useState } from 'react';
import DeviceRemoveModal from './DeviceRemoveModal';

interface DeviceDetailProps {
    deviceId: string;
  }

const DeviceDetail: React.FC<DeviceDetailProps> = ({deviceId}) =>{

  // Device remove modal
  // Modal state management
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Sensor API
  const {data: sensorData, isLoading: sensorIsLoading, error: sensorError} = useQuery<SensorForDeviceType[], AxiosError>({
    queryKey:['sensorForDevice', deviceId],
    queryFn: ()=>getSensorForDevice(deviceId),
    refetchInterval:60000,
  });
  
  // Switch API
  const {data: switchData, isLoading: switchIsLoading, error: switchError} = useQuery<SwitchType[], AxiosError>({
    queryKey:['switchForDevice', deviceId],
    queryFn: ()=>getSwitchForDevice(deviceId)
  });

  // Device API
  const { data, isLoading, error } = useQuery<DeviceType, AxiosError>({
    queryKey: ['deviceDetail', deviceId],
    queryFn: ()=>getDeviceDetail(deviceId),
  });

  const user: UserType = useAppSelector((state: RootState) => state.user);

  if (isLoading || sensorIsLoading || switchIsLoading || !data) return <Spinner />;
  
  if (error instanceof Error) return <p className="text-center text-red-500">Error: {error.message}</p>;
  if (sensorError instanceof Error) return <p className="text-center text-red-500">Error: {sensorError.message}</p>;
  if (switchError instanceof Error) return <p className="text-center text-red-500">Error: {switchError.message}</p>;

  let readableDate = '';

  if (data) {
    const date = new Date(data.created_at);
    readableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    }); // Format based on user's locale
  }
  // let isOnline = false;
  const isOnline = data?.status==='Online';
  const cssClass = {
    title: 'text-lg font-medium leading-6 text-gray-900',
    value: 'mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0'
  };


  return (
    <div className='max-w-5xl'>
      <div className="px-4 sm:px-0">
        <h3 className="text-2xl font-semibold leading-7 text-gray-900">{data.name? data.name: '-- no device name --'}</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{data.description}</p>
      </div>
      <div className="mt-4 border-t border-gray-300">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className={cssClass.title}>Type</dt>
            <dd className={cssClass.value}>{data.type}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className={cssClass.title}>Status</dt>
            <dd className={cssClass.value}>
              <span className={`inline-block h-2.5 w-2.5 rounded-full mr-2.5 ${isOnline ? 'bg-green-500' : 'bg-red-500'}`} />
              {isOnline ? 'Online' : 'Offline'}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className={cssClass.title}>Created on:</dt>
            <dd className={cssClass.value}>{readableDate}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className={cssClass.title}>Group:</dt>
            <dd className={cssClass.value}>{data.group_name}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className={cssClass.title}>Description</dt>
            <dd className={cssClass.value}>
              {data.description}
            </dd>
          </div>
          
        </dl>
  

      </div>
      {sensorData && sensorData.length > 0 && <h1 className={`${cssClass.title} mt-6 mb-4 border-b border-gray-300 pb-2`}>Sensors</h1>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {sensorData?.map((sensor)=>(
          <SensorCard key={sensor.id} {...sensor} />
        ))}
      </div>
      {switchData && switchData?.length > 0 && <h1 className={`${cssClass.title} mt-6 mb-4 border-b border-gray-300 pb-2`}>Switches</h1>}
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {switchData?.map((item)=>(
          <SwitchCard key={item.id} {...item} initialState={item.state} />
          // <h1 key={item.id} className='text-green-700'>Switch: {item.name}</h1>
        ))}
      </div>

      <div className='mt-6'>
        {   
          user.role === 'tech_support' &&<Link to={`/tech-support/add-sensor/${data.id}`} className="text-sky-500 hover:text-sky-800 mx-3">
          Add Sensor
          </Link>
        }

        {   
          user.role === 'tech_support' &&<Link to={`/tech-support/add-switch/${data.id}`} className="text-sky-500 hover:text-sky-800 mx-3">
          Add Switch
          </Link>
        }
    
        <span onClick={openModal} className="text-red-500 hover:text-red-800 cursor-pointer">
          Remove Device
        </span>
        <DeviceRemoveModal
          isOpen={isModalOpen}
          onClose={closeModal}
          deviceId={data.id}
          title="Remove Device"
        />
  
      </div>
    </div>
  );
};

export default DeviceDetail;